export default function(options) {
  return {
    title: {
      text: options.titleText || '区域分析',
      left: 'center'
    },
    tooltip: {
      trigger: 'item'
    },
    visualMap: {
      min: 0,
      max: 500,
      text: ['High', 'Low'],
      realtime: false,
      calculable: true,
      inRange: {
        color: ['white', 'yellow', 'orangered']
      }
    },
    series: [
      {
        type: 'map',
        mapType: 'china',
        roam: false,
        name: options.seriesName || '数量',
        label: {
          normal: {
            show: true,
            textStyle: {
              fontSize: 12,
              fontWeight: 'normal',
              color: 'black'
            }
          },
          emphasis: {
            show: true
          }
        },
        data: options.seriesData || []
      }
    ]
  }
}
